import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/video-controls-hider-for/mjldjdmochmoihnmpgeboiikjinfjgco"
      }}>{`Video Controls Hider for YouTube™`}</a>{` is a simple Chrome extension that injects a simple css file into any YouTube page, hiding the video playback controls and animations when the video is fullscreen. The source code is hosted `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/video-controls-hider"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      